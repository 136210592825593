@mixin respond($breakpoint) {
    @if $breakpoint == xs {
      @media only screen and (max-width: 350px) {
        @content;
      }
    }
    @if $breakpoint == sm {
      @media only screen and (max-width: 320px) {
        @content;
      }
    }
    @if $breakpoint == phone {
      @media only screen and (max-width: 600px) {
        @content;
      }
    }
    @if $breakpoint == tab-port {
      @media only screen and (max-width: 900px) {
        @content;
      }
    }
    @if $breakpoint == tab-land {
      @media only screen and (max-width: 1220px) {
        @content;
      }
    }
    @if $breakpoint == desktop {
      @media only screen and (max-width: 1440px) {
        @content;
      }
    }
    @if $breakpoint == big-desktop {
      @media only screen and (min-width: 1800px) {
        @content;
      }
    }
  }