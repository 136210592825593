@import '@styles/media.scss';

.MainWrapper {
}

.hasPadding {
    padding: var(--spacing-mainWrapper);
    @include respond(phone){
        padding: 3rem 1rem;
        width: inherit !important;
    }
}