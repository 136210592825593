@import '@styles/media.scss';

.TrackingSteps {
    width: fit-content;
    margin-left: 3rem;
    display: block;

    @include respond(phone) {
        margin: 3rem .5rem 0rem 0;

        :global(.MuiTypography-h3Bold) {
            font-size: var(--font-h4);
            text-align: center;
        }
    }

    @include respond(phone) {
        &__Title {
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    &__Trackings {
        max-height: 50vh;
        overflow: scroll;
        ::-webkit-scrollbar{
            width: 7px;
        }

        @include respond(phone) {
            max-height: none;
        }
    }


    &__icon {
        height: fit-content;
        position: relative;
        background-color: white;
    }

    &__line {
        width: 4px;
        margin: auto;
        background-color: var(--green300);
        height: 100%;
        position: absolute;
        z-index: -1;

        &Container {
            display: flex;
            justify-content: center;
            position: relative;
        }
    }

    &__item {
        display: flex;
        flex-wrap: nowrap;
        gap: 2rem;

        &Wrapper {
            margin-bottom: 1rem;
        }

        &__header {
            width: 100%;
            display: flex;
            align-items: flex-end;
            margin-bottom: .3rem;

            :global(.MuiTypography-caption) {
                margin-left: 1rem;
            }
        }
    }
}