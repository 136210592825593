@import "@styles/media.scss";


body {
  font-family: var(--font);
  margin: 0;
  padding: 0;
  width: 100%;
}

/* html {
  font-size: 16px;

  @include respond(phone) {
    font-size: 14px
  }
} */

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.swiper-button-prev {
  background-image: url(./assets/icons/arrow-left.svg);
  width: 21px;
  background-repeat: no-repeat;
  background-position: center center;
  left: 2rem !important;

  &:after {
    content: "" !important;
  }
}

.swiper-button-next {
  background-image: url(./assets/icons/arrow-right.svg);
  width: 21px;
  background-repeat: no-repeat;
  background-position: center center;
  right: 2rem !important;

  &:after {
    content: "" !important;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d3cdd5 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3cdd5;
  border-radius: 10px;
  border: 3px solid #ffffff;
}