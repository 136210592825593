@import "@styles/media.scss";   

.Servicios {
  background-color: var(--green700);
  padding: 3rem;
  gap: 2rem;
  overflow: hidden;
  &__Items {
    width: 100%
  }

  &__Item {
    img{
      max-width: 104px;
      max-height: 104px;
      border-radius: 100%;
      background-color: white;
      @include respond(phone){
        max-width: 80px;
      }
    }
  }

  &__Carrousel {
    :global(.swiper-slide) {
      display: flex;
      justify-content: center;
    }

    @include respond(phone) {
      :global(.swiper-slide) {
        display: flex;
        justify-content: center;
        margin: 0 1rem;
      }
  
      &:global(.swiper) {
        overflow: visible;
      }
    }
  }
}