@import '@styles/media.scss';

.Faq {
    &__title {
        margin: 8rem 0 5rem;

        @include respond(phone) {
            text-align: left;
            margin: 5rem 0 2rem;
        }
    }

    :global(ul) {
        padding-left: 3rem;
    }

    :global(li) {
        list-style: disc;
    }

    &__container {
        max-width: 70%;
        align-items: center;

        @include respond(phone) {
            width: 100%;
            max-width: 100%;
        }
    }

    @include respond(phone) {
        margin: 0;

        :global(.MuiAccordionSummary-root) {
            padding: .5rem 0 !important
        }
    }
}

.Form {
    margin-top: 4rem;

    &__header {
        margin: 2rem 0 3rem;

        @include respond(phone) {
            margin: 2rem 0;

            :global(.MuiTypography-h4) {
                font-size: var(--font-h6);
                margin-top: .5rem;
            }
        }
    }
}