@import '@styles/media.scss';

.Home{
    padding-top: var(--nav-height);
}

.Carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    &__Item{
        min-height: 70vh;
        color: var(--color-bg);
        display: flex;
        justify-content: center;
        background-position: center center;
        background-size: cover;
    }
    &__Container{
        width: 70%;
        margin: auto;
        @include respond(phone){
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            :global(.MuiButton-root){
                margin-top: 2rem;
            }
        }
    }
    &__Title{
        margin-bottom: 1.5rem;

        @include respond(phone){
            font-size: var(--font-h2);
        }
    }
    &__Subtitle{
        margin-bottom: 2rem;
    }
}
