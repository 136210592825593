@import "@styles/media.scss";

.Tracking {
    margin: 4rem 0;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__subtitle {
        margin: 1rem 0 3rem
    }

    @include respond(phone) {
        margin: 0;

        >:global(.MuiGrid-root) {
            margin: 0;
            width: 100%;
        }
    }
}

.Input {
    width: 100%;
    margin-bottom: 3rem;
}