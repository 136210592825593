@import '@styles/media.scss';

.Footer {
  background: var(--yellow500);
  &__Wrapper {
    width: 80%;
    margin: auto;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include respond(phone){
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      gap: 2rem;
      padding: 3rem 0;
    }
  }
  &__Links {
    display: flex;
    flex-direction: column;
    justify-self: center;
    gap: .5rem;
    :global(a) {
      color: var(--blue700);
      font-weight: 400;
      font-size: var(--font-caption);
    }
    @include respond(phone){
      text-align: left;
    }
  }
  &__Logo {
    justify-self: flex-end;
    align-self: flex-end;
    @include respond(phone){
      justify-self: center;
      align-self: center;
    }
  }
}
