@import '@styles/media.scss';

.TrackingDetailStatus {
  @include respond(phone) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 2rem;

    :global(.MuiTypography-h2Bold) {
      font-size: var(--font-h3);
    }
  }

  &__controls {
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: .7rem;
    margin-top: .5rem;
  }

  &__link {
    color: var(--green700);
    text-align: center;
    font-weight: 600;
    font-size: var(--font-caption);
  }
}