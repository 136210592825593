
@keyframes slideInFromLeft {
    0% {
      transform: translateY(-100%);
    }
    50% {
      transform: translateY(0);
    }
    75%{
      transform: translateY(100%);
    }
    100%{
      transform: translateY(100%);
    }
  }
.ScreenTransition{
    animation: 1500ms ease-out 0s 1 slideInFromLeft;
    background: var(--yellow500);
    padding: 30px;
    height: 100vh;
    width: 100vw;
    z-index: 9999999;
    position: fixed
}
