@import '@styles/media.scss';

.TrackingDetail {
    margin: 4rem 0;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10rem;
    padding-right: 10rem;

    &__subtitle {
        margin: 1rem 0 3rem
    }

    @include respond(big-desktop) {
        width: 80%;
        margin: 4rem auto;
    }
    @include respond(phone) {
        width: 100%;
        padding: 3rem 1rem;
    }
    

    &__controls {
        width: fit-content;
        justify-content: center;
        align-items: center;
        gap: .7rem;
        margin-top: .5rem;
    }
    &__link {
        color: var(--green700);
        text-align: center;
        font-weight: 600;
        font-size: var(--font-caption);
      }
}

.Input {
    width: 100%;
    margin-bottom: 3rem;
}