@import "@styles/variables.scss";
@import "@styles/media.scss";
@import "@styles/mixins.scss";

.Nav {
  min-height: var(--nav-height);
  padding: 0.5rem;
  position: fixed;
  width: 100%;
  color: var(--blue700);
  top: 0;
  transition: all 0.5s;
  z-index: 999;
  display: block;
  overflow: hidden;
  background-color: var(--yellow500);
  display: flex;
  justify-content: space-between;

  .Logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 203px;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    width: 100%;
  }

  .LinksContainer {
    display: flex;
    justify-content: space-between;
    margin: 0;
    //min-width: 60%;
    gap: 3rem;

    li {
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        background: var(--green700);
        border-radius: 10px;
        padding: 0.7rem 2.5rem;
        font-weight: 600;

        a {
          color: var(--white100);
        }
      }
    }
  }
}

.Link {
  color: var(--blue700);
  font-weight: 500;
}

.ScrolledNav {
  background: var(--yellow500);
  transition: all 0.5s;
  top: 0px;

  @include respond(phone) {
    padding: 0.4em 0.7em;
  }
}

.hiddenNav:not(.isMobile) {
  top: -100px;
  transition: all 0.5s;
}