:root {
  --green500: #2fdd92;
  --green700: #34be82;
  --green700-hover: rgb(36, 159, 106);

  --color-bg: #fff;

  --blue500: #2f86a6;
  --blue700: #000957;

  --blue500-hover: #2a7692;
  --black-700: #000000;

  --gray300: #808080;
  --gray100: #BDBDBD;

  --white100: #fff;

  --yellow500: #F2F013;

  --color-text-secondary: #000000;
  --color-bg-action: #000957;
  --color-bg-light: #000957;
  --color-success: #2fdd92;
  --green300: #2fdd92;

  --font: "Poppins", sans-serif;

  --font-caption: 15px;
  --font-h6: 16px;
  --font-h5: 18px;
  --font-h4: 20px;
  --font-h3: 25px;
  --font-h2: 30px;
  --font-h1: 40px;
  --nav-height: 60px;

  --spacing-mainWrapper: 7rem 3rem;
  --padding-button: 10px 40px;
}