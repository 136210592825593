@import '@styles/media.scss';

Accordion {
    :global(.Mui-expanded) {
        :global(.MuiTypography-body1) {
            font-weight: 700;
        }
    }

    &__Title {
        font-weight: 400;
        margin: 0rem 2rem;

        @include respond(phone) {
            margin: 0;
        }
    }

    &__Details {
        margin: 0rem 2rem;
    }

    &__Summary {
        margin: 1rem 0;
        padding-top: 0;
    }
}