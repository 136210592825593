@import "./../../../../styles/media.scss";
@import "./../../../../styles/mixins.scss";

%transition {
    opacity: 0;
    transition: 0.3s ease-in all;
}

%opacity {
    opacity: 1;
    transform: translateY(10px);
}



%flex-stack {
    display: flex;
    align-items: center;
    justify-content: center;
}


.isMobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    transition: all 0.5s;

    &.hiddenNav {
        top: -100px;
        transition: all 0.5s;
    }

    .NavWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background: var(--yellow500);
    }

    .container {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 1rem;

        h2 {
            color: var(--primary-light-bg);
            font-size: 2rem;
            text-align: center;
            word-spacing: 0.2rem;
        }
    }

    .burger {
        position: relative;
        vertical-align: middle;
        display: inline-block;
        width: 30px;
        height: 19px;
        pointer-events: auto;
        cursor: pointer;
        width: 26px;
        height: 17px;

        span {
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            transition: all 0.3s;
            background: var(--blue700);
            -webkit-transition: opacity 120ms linear, -webkit-transform 240ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: opacity 120ms linear, -webkit-transform 240ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: transform 240ms cubic-bezier(0.215, 0.61, 0.355, 1), opacity 120ms linear;
            transition: transform 240ms cubic-bezier(0.215, 0.61, 0.355, 1), opacity 120ms linear,
                -webkit-transform 240ms cubic-bezier(0.215, 0.61, 0.355, 1);

            &:nth-child(2) {
                top: 7px;
            }

            &:nth-child(3) {
                top: 14px;
            }
        }

        cursor: pointer;
        z-index: 1;
        display: flex;

        i {
            &:nth-of-type(1) {
                display: block;
            }

            &:nth-of-type(2) {
                display: none;
            }
        }

        &.burgerOpen {
            color: var(--green500);

            i {
                &:nth-of-type(1) {
                    display: none;
                }

                &:nth-of-type(2) {
                    display: block;
                }
            }
        }
    }

    .navbar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        color: var(--primary-light-bg);
        clip-path: circle(0px at 0 0px);
        transition: 0.5s ease all;
        z-index: 9;

        .navLinks {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            li {
                text-align: center;
                list-style-type: none;
                margin: 1rem 0;
                &:nth-of-type(1) {
                    @extend %transition;

                    &.navLinkOpen {
                        @extend %opacity;
                        transition-delay: 0.25s;
                    }
                }

                &:nth-of-type(2) {
                    @extend %transition;

                    &.navLinkOpen {
                        @extend %opacity;
                        transition-delay: 0.35s;
                    }
                }

                &:nth-of-type(3) {
                    @extend %transition;

                    &.navLinkOpen {
                        @extend %opacity;
                        transition-delay: 0.45s;
                    }
                }

                &:nth-of-type(4) {
                    @extend %transition;

                    &.navLinkOpen {
                        @extend %opacity;
                        transition-delay: 0.55s;
                    }
                }

                &:nth-of-type(5) {
                    @extend %transition;

                    &.navLinkOpen {
                        @extend %opacity;
                        transition-delay: 0.65s;
                    }
                }

                a {
                    text-decoration: none;
                    color: var(--primary-bg);
                    font-size: var(--font-h2);
                    font-weight: 900;
                    position: relative;
                    color: black;

                    &.activeCategory {
                        @include activeLink($isDark: true);
                    }
                }
            }
        }

        &.navOpen {
            display: block;
            clip-path: circle(100%);
        }
    }

    &.navOpen {
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 999;

        .Logo {
            color: var(--primary-bg);
        }

        .navbar {
            background: var(--yellow500);
        }

        .burger {
            z-index: 999;

            span {
                background: var(--blue700);

                &:nth-child(1) {
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: 7px;
                }

                &:nth-child(3) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }
}