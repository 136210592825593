@import "./media.scss";

@mixin activeLink($isDark: true) {
  @if $isDark {
    color: var(--primary-bg);
  } @else {
    color: var(--primary-light-bg);
  }
}

@mixin button($variant: primary) {
  padding: var(--padding-button);
  border-radius: 10px;
  display: block;
  width: fit-content;
  font-size: var(--font-h6);

  font-weight: 600;
  @if $variant == primary {
    background: var(--blue500);
    color: var(--white100);
  } @else if $variant == secondary {
    background: var(--green700);
    color: var(--white100);
    transition: 300ms all;

    &:hover {
      background-color: var(--green700-hover);
      transition: 300ms all;
      color: var(--white100);
    }
  }
}
